export const reasonOptions = [
  'Image Not Visible Properly',
  'Not A Valid Document',
  'Document Validation Expired',
  'Add Custom Reason'
]

export const tableHeaders = [
  { label: 'Id', value: 'userBonusId' },
  { label: 'Promotion Title', value: 'promotionTitle' },
  { label: 'Bonus Type', value: 'bonusType' },
  { label: 'Valid Till', value: 'validTo' },
  { label: 'Is Expired', value: 'isExpired' },
  { label: 'Status', value: 'isActive' },
  { label: 'Issued By', value: 'issuerRole' },
  { label: 'Issued At', value: 'createdAt' },
  { label: 'Claimed At', value: 'claimedAt' },
  { label: 'Amount To Wager', value: 'amountToWager' },
  { label: 'Wagered Amount', value: 'wageredAmount' },
  { label: 'Deposit Amount', value: 'cashAmount' },
  { label: 'Bonus Amount', value: 'bonusAmount' },
  { label: 'Cancelled By', value: 'cancelledBy' },
  { label: 'Updated At', value: 'updatedAt' },
  { label: 'Action', value: 'action' }
]

export const bonusStatus = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Forfeited', value: 'FORFEITED' },
  { label: 'Expired', value: 'EXPIRED' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Zeroed Out', value: 'ZEROED-OUT' }
]

export const bonusTypes = [
  { label: 'All', value: '' },
  { label: 'MATCH', value: 'match', id: 0 },
  { label: 'BALANCE', value: 'balance', id: 1 },
  { label: 'FREESPINS', value: 'freespins', id: 2 },
  { label: 'DEPOSIT(CASHBACK)', value: 'deposit', id: 4 },
  { label: 'WAGERING(CASHBACK)', value: 'wagering', id: 5 },
  { label: 'MANUAL', value: 'manual', id: 6 },
  // { label: 'ZERO DEPOSIT', value: 'zero_deposit', id: 8 }
]

export const affiliateList = [
  { label: 'Parent Affiliate ID', value: 'parentIdAffiliate' },
  { label: 'Parent Username', value: 'parentUsername' },
  { label: 'Affiliate ID', value: 'userIdAffiliate' },
  { label: 'User Name', value: 'username' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Email', value: 'email' },
  { label: 'Country', value: 'country' },
  { label: 'Currency', value: 'currency' },
  { label: 'Mobile', value: 'mobile' }
]

export const userDisableReasons = [
  { value: 'Bonus abuser', label: 'Bonus Abuser' },
  { value: 'Duplicate account', label: 'Duplicate Account' },
  { value: 'Fraudulent', label: 'Fraudulent' },
  { value: 'Risk country', label: 'Risk Country' },
  { value: 'Rude behaviour', label: 'Rude Behaviour' },
  { value: 'Bannec country - connect with VPN or Tor Browser', label: 'Bannec Country - Connect With VPN Or Tor Browser' },
  { value: 'KYC declined', label: 'KYC Declined' },
  { value: 'Suspicios - constant deposits and withdrawals (money laundering)', label: 'Suspicious - Constant Deposits And Withdrawals (Money Laundering)' },
  { value: 'Contacts support for bigger amount of deposit than allowed', label: 'Contacts Support For Bigger Amount Of Deposit Than Allowed' },
  { value: 'Resonsible Gambling', label: 'Responsible Gambling' },
  { value: 'pending', label: 'Pending' },
  { value: 'frozen', label: 'Frozen' },
  { value: 'deactivated', label: 'Deactivated' },
  { value: 'permbanned', label: 'Permbanned' },
  { value: 'tempbannedblocked', label: 'Temp Banned Blocked' },
  { value: 'gambling_issues', label: 'Gambling Issues' },
  { value: 'self_excluded', label: 'Self Excluded' },
  { value: 'dpo_erasure_requested', label: 'DPO Erasure Requested' },
  { value: 'kyc_blocked_suspended', label: 'KYC Blocked Suspended' },
  { value: 'kyc_blocked_duplicate', label: 'KYC Blocked Duplicate' },
  { value: 'kyc_blocked_bonus_abuse', label: 'KYC Blocked Bonus Abuse' },
  { value: 'kyc_blocked_chargeback', label: 'KYC Blocked Chargeback' },
  { value: 'kyc_fraud', label: 'KYC Fraud' },
  { value: 'failed_kyc', label: 'Failed KYC' },
  { value: 'aml_failed_sow', label: 'AML Failed SOW' },
  { value: 'aml_management_ban', label: 'AML Management Ban' },
  { value: 'aml_on_hold', label: 'AML On Hold' },
  { value: 'aml_under_review', label: 'AML Under Review' },
  { value: 'rg_underaged', label: 'RG Underaged' },
  { value: 'rg_under_review', label: 'RG Under Review' },
  { value: 'enforced_self_ex', label: 'Enforced Self Exclusion' },
  { value: 'location_ban', label: 'Location Ban' },
  { value: 'cs_management_ban', label: 'CS Management Ban' },
  { value: 'validated', label: 'Validated' },
  { value: 'Other', label: 'Other' }
]

export const walletConstants = {
  'Cash Balance': 'amount',
  'Bonus Balance': 'nonCashAmount',
  'Pending Withdrawals': 'pendingWithdrawals',
  'Pending Deposits': 'pendingDeposits',
  'Player Net Loss': 'netLoss',
  'User Deposits': 'userDeposits',
  'Add Money': 'addMoney',
  'Total Withdrawals': 'totalWithdrawals',
  'Bonus Amount Converted': 'totalBonus',
  'Bonus Bet': 'bonusBetWagering',
  'Deposit To Bonus Ratio': 'depositToBonus',
  'Average Deposit': 'averageDeposit',
  'Average Withdrawal': 'averageWithdrawal',
  'Average Bonus': 'averageBonus',
  'Cancelled Amount': 'cancelledDeposits',
  'Chargeback Amount': 'chargebackAmount',
  'Refund Amount': 'refundAmount',
  'Bonus Wins': 'bonusWins',
  'Total Daily Cashback Issued': 'dailyCashbackIssued',
  'Total Weekly Cashback Issued': 'weeklyCashbackIssued',
  'Total Monthly Cashback Issued': 'monthlyCashbackIssued',
  'Total Daily Cashback Converted': 'dailyCashbackConverted',
  'Total Weekly Cashback Converted': 'weeklyCashbackConverted',
  'Total Monthly Cashback Converted': 'monthlyCashbackConverted',
  'Total Cashback Issued': 'totalCashbackIssued',
  'Total Cashback Converted': 'totalCashbackConverted'
}

export const mapffiliateDataConstant = {
  Deposits: 'deposits',
  'Casino Ngr': 'casinoNgr',
  'Casino Bets': 'casinoBets',
  'Casino Wins': 'casinoWins',
  Chargeback: 'chargeback',
  'Jackpot Wins': 'jackpotWins',
  'Casino Cost Bonus': 'casinoCostBonus'
}

export const goffiliateDataConstant = {
  Deposits: 'deposits',
  Withdrawals: 'withdrawals',
  'Casino Bets': 'casinoBets',
  'Casino Wins': 'casinoWins',
  'Casino Bonus Bets': 'casinoBonusBets',
  'Casino Bonus Wins': 'casinoBonusWins',
  'Jackpot Wins': 'jackpotWins',
  'Cash Casino Cost Freeround': 'cashCasinoCostFreeround',
  Cashback: 'cashback',
  Chargeback: 'chargeback',
  'Money Retracted': 'moneyRetracted',
  'Casino Cost Bonus': 'casinoCostBonus',
  'Money Added': 'moneyAdded',
  'Non Cash Money Added': 'nonCashMoneyAdded',
  'Non Cash Money Removed': 'nonCashMoneyRemoved',
  'Forfeited Bonuses': 'forfeitedBonuses'
}

export const affiliateDataConstants = {
  ...goffiliateDataConstant,
  'Casino Ngr': 'casinoNgr',
  'First Deposit Amount': 'firstDepositAmount',
  'Non Cash Casino Cost Freeround': 'nonCashCasinoCostFreeround',
  'Total Ngr': 'totalNgr'
}
