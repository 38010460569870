import { useEffect, useState } from 'react'
import { isTenant } from '../../../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { updateChatIntegrationStart } from '../../../../store/redux-slices/tenantcredentials'
import { getChatIntegration } from '../../../../utils/apiCalls'
import { toast } from '../../../../components/Toast'
import useCheckPermission from '../../../../utils/checkPermission'
import { getItem } from '../../../../utils/storageUtils'

const useChatSupport = () => {
  const { chatIntegrationKeys } = useSelector(state => state.tenantcredentials)
  const { tenantDetails } = useSelector(state => state.login)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [selectedChatProvider, setSelectedChatProvider] = useState(tenantDetails?.tenantConfig?.chatConfig?.chatProvider || '')
  const dispatch = useDispatch()
  const [myKeys, setMyKeys] = useState({})
  const [tenantData, setTenantData] = useState(null)
  const { isHidden } = useCheckPermission()
  const tenantId = getItem('tenant-id')

  useEffect(() => {
    if (!isTenant && selectedPortal) {
      getChatIntegration(selectedPortal)
        .then((response) => {
          if (response?.data?.data?.getChatSupportDetail?.chatConfig?.chatProvider) {
            const tenantConfig = response?.data?.data?.getChatSupportDetail?.chatConfig
            setTenantData(tenantConfig)
            setMyKeys(Object.keys(tenantConfig).filter((key) => key !== 'chatProvider'))
          } else {
            setTenantData(null)
            setMyKeys({})
          }
        })
        .catch(() => {
          toast('Not able to fetch tenant data', 'error')
          setSelectedPortal('')
        })
    }
  }, [selectedPortal])

  useEffect(() => {
    if (tenantDetails?.tenantConfig?.chatConfig?.chatProvider) {
      setMyKeys(chatIntegrationKeys?.[tenantDetails?.tenantConfig?.chatConfig?.chatProvider])
    } else {
      setMyKeys(chatIntegrationKeys?.[selectedChatProvider])
    }
  }, [selectedChatProvider])

  const getInitialValues = () => {
    if (!isTenant) {
      if (tenantData) {
        return ({ selectedChatProvider: tenantData?.chatProvider, ...tenantData })
      }
    } else if (myKeys?.length) {
      return ({
        selectedChatProvider: selectedChatProvider || '',
        ...myKeys.reduce((acc, currValue) => ({
          ...acc,
          [currValue]: tenantDetails?.tenantConfig?.chatConfig?.[currValue] || ''
        }), {})
      })
    }

    return ({ selectedChatProvider: '' })
  }

  const handleSubmit = (chatProvider, values) => {
    dispatch(updateChatIntegrationStart({
      data: {
        tenantId: isTenant ? tenantId : selectedPortal, chatConfig: { chatProvider, ...values }
      },
      isTenant
    }))
  }

  return {
    chatIntegrationKeys,
    tenantDetails,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    selectedChatProvider,
    setSelectedChatProvider,
    myKeys,
    setMyKeys,
    tenantData,
    setTenantData,
    getInitialValues,
    handleSubmit,
    isHidden
  }
}

export default useChatSupport
