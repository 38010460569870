import { ErrorMessage, Form, Formik } from 'formik'
import { Form as BForm, Button, Col, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import PortalFilter from '../../../../components/PortalFilter'
import ClientFilter from '../../../../components/ClientFilter'
import { isTenant } from '../../../../utils/constants'
import { affiliateSystemSchema } from '../schemas'
import useCrmSettings from '../hooks/useCrmSettings'

const CRMSettings = () => {
  const {
    crmIntegrationKeys,
    tenantDetails,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    selectedCrm,
    setSelectedCrm,
    myKeys,
    tenantData,
    showLaunchButton,
    getInitialValues,
    handleSubmit,
    handleLaunchCrm,
    isHidden
  } = useCrmSettings()

  return (
    <>
      <Row>
        {!isTenant && (
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <ClientFilter
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>
        )}
        {selectedClient && (
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <PortalFilter
              selectedClient={selectedClient}
              selectedPortal={selectedPortal}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>
        )}
      </Row>

      {(isTenant || (!isTenant && selectedPortal)) && (
        <Formik
          enableReinitialize={selectedCrm || myKeys?.length || tenantDetails}
          initialValues={getInitialValues()}
          validationSchema={affiliateSystemSchema(myKeys)}
          onSubmit={(formValues) => {
            const { selectedCrm, ...rest } = formValues
            handleSubmit(selectedCrm, rest)
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form className='align-items-center mt-3'>
              {(isTenant || (selectedClient && selectedPortal)) && (
                <Row className='d-flex align-items-center mt-3'>
                  <Col xs={8} md={4}>
                    <BForm.Label>CRM Provider</BForm.Label>
                  </Col>
                  <Col>
                    <BForm.Select
                      size='sm'
                      disabled={tenantDetails?.tenantConfig?.crmConfig?.crmIntegrated || tenantData?.crmIntegrated}
                      style={{ width: '220px' }}
                      name='selectedCrm'
                      value={values.selectedCrm}
                      onChange={(e) => {
                        setSelectedCrm(e.target.value)
                        handleChange(e)
                      }}
                    >
                      <option value=''>---Select---</option>
                      {crmIntegrationKeys && Object.keys(crmIntegrationKeys).map((crmIntegrationKey) => (
                        <option key={crmIntegrationKey} value={crmIntegrationKey}>{crmIntegrationKey}</option>
                      ))}
                    </BForm.Select>
                  </Col>
                </Row>
              )}

              {myKeys?.length && myKeys?.map(credKey => (
                <Row key={credKey} className='mt-3 d-flex align-items-center'>
                  <Col xs={8} md={4}>
                    <BForm.Label>{credKey}</BForm.Label>
                  </Col>
                  <Col>
                    <BForm.Control
                      type='text'
                      disabled={isHidden({ module: { key: 'TenantSettings', value: 'U' } })}
                      style={{ maxWidth: '420px', minWidth: '220px' }}
                      name={credKey}
                      placeholder='Enter Value'
                      value={values[credKey]}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      component='div'
                      name={credKey}
                      className='text-danger'
                    />
                  </Col>
                </Row>
              ))}
              <div
                className='mt-4 d-flex justify-content-center'
              >
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  hidden={!values.selectedCrm && isHidden({ module: { key: 'TenantSettings', value: 'U' } })}
                >
                  Submit
                </Button>

                <Button
                  variant='outline-warning'
                  style={{ marginLeft: '20px' }}
                  onClick={handleLaunchCrm}
                  hidden={!showLaunchButton}
                >
                  Launch CRM
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default CRMSettings
