import React, { useEffect } from 'react'
import { createSubCategorySchema, editSubCategorySchema } from '../schema'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  Modal,
  InputGroup
} from '@themesberg/react-bootstrap'

import useCreateSubCategory from '../hooks/useCreateSubCategory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { languageCode } from '../../TenantConfigurations/Languages/constants'

const CreateSubCategory = ({
  selectedSubCategory,
  handleClose,
  limit,
  pageNo,
  search,
  categoryFilter,
  showModal,
  type,
  statusFilter
}) => {
  const {
    tenantGameCategoryId: editTenantGameCategoryId,
    tenantGameSubCategoryId,
    name: editName,
    isActive: editIsActive,
    isLabeledCategory: editIsLabeledCategory,
    imageUrl: editImageUrl,
    labelIconUrl: editLabelIconUrl
  } = !!selectedSubCategory && selectedSubCategory

  const {
    loading,
    updateCasinoMenu,
    createCasinoMenu,
    casinoCategories,
    languages,
    language,
    setLanguage,
    createName,
    setCreateName
  } = useCreateSubCategory()

  useEffect(() => {
    if (type === 'Edit' && editName) {
      setCreateName(editName)
    } else {
      setCreateName({ EN: '' })
    }
  }, [type, editName, showModal])

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <h4 className='d-flex align-items-center'>
          {type} Casino Sub Category
        </h4>
      </Modal.Header>

      <Formik
        enableReinitialize
        initialValues={{
          isActive: editName ? editIsActive : false,
          isLabeledCategory: editName ? editIsLabeledCategory : false,
          subCategoryName: createName || '',
          tenantGameCategoryId: editTenantGameCategoryId || '',
          imageUrl: '',
          labelIconUrl: ''
        }}
        validateOnBlur={false} // Disable validation on blur
        validateOnChange={false} // Disable validation on change
        validationSchema={editName ? editSubCategorySchema(createName) : createSubCategorySchema(createName)}
        onSubmit={({ isActive, subCategoryName, tenantGameCategoryId, imageUrl, labelIconUrl, isLabeledCategory }) => {
          editName
            ? updateCasinoMenu({
              data: {
                isActive,
                isLabeledCategory,
                tenantGameCategoryId: parseInt(tenantGameCategoryId),
                name: subCategoryName,
                casinoSubCategoryId: tenantGameSubCategoryId,
                subcategoryImage: imageUrl || editImageUrl,
                labelImage: labelIconUrl || editLabelIconUrl
              },
              limit,
              pageNo,
              search,
              categoryFilter,
              isActive: statusFilter
            })
            : createCasinoMenu({
              data: {
                isActive,
                isLabeledCategory,
                name: subCategoryName,
                tenantGameCategoryId,
                subcategoryImage: imageUrl || editImageUrl,
                labelImage: labelIconUrl || editLabelIconUrl
              },
              limit,
              pageNo,
              search,
              categoryFilter,
              isActive: statusFilter,
            })

          handleClose()
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur, setFieldValue, errors }) => (
          <Form>
            <Modal.Body>
              <Row className='mt-3'>
                <Col className='d-flex'>
                  <Col>
                    <BForm.Label>
                      Select Language<span className='text-danger'> *</span>
                    </BForm.Label>
                  </Col>
                  <Col>
                    <BForm.Select
                      name='language'
                      value={language}
                      onChange={(e) => {
                        setLanguage(e.target.value)
                        const label = Object.assign({}, values?.subCategoryName)
                        if (label[e.target.value] === undefined) {
                          label[e.target.value] = ''
                        }
                        setCreateName(label)
                      }}
                    >
                      <option value=''>Select Language</option>
                      {languages?.length && languages?.map((code) => {
                        return !Object.keys(createName)?.includes(code) && (
                          <option key={code} value={code}>{languageCode[code]}({code})</option>
                        )
                      })}
                    </BForm.Select>
                  </Col>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Sub Category Name <span className='text-danger'>*</span>
                  </BForm.Label>

                  {Object.keys(values?.subCategoryName).map((language) => {
                    return (
                      <div className='d-flex align-items-center mt-1' key={language}>
                        <Col>
                          <Col className='d-flex m-1'>
                            <InputGroup>
                              <InputGroup.Text>{language}</InputGroup.Text>
                              <BForm.Control
                                type='text'
                                name={`subCategoryName[${language}]`}
                                placeholder='Enter Menu Name'
                                value={values.subCategoryName[language]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </InputGroup>

                            <Button
                              variant='danger'
                              disabled={language === 'EN' || editName?.[language] !== undefined}
                              className='m-1'
                              onClick={() => {
                                const label = Object.assign({}, values?.subCategoryName)
                                delete label[language]
                                setCreateName(label)
                                setFieldValue('subCategoryName', label)
                                setLanguage('EN')
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </Col>

                          <ErrorMessage
                            component='div'
                            name={`subCategoryName[${language}]`}
                            className='text-danger'
                          />
                        </Col>
                      </div>
                    )
                  })}
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Category <span className='text-danger'>*</span>
                  </BForm.Label>

                  <BForm.Select
                    value={values.tenantGameCategoryId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name='tenantGameCategoryId'
                  >
                    <option value=''>Select Category</option>

                    {casinoCategories && casinoCategories?.rows?.map((c) => (
                      <option
                        key={c?.tenantGameCategoryId}
                        value={c?.tenantGameCategoryId}
                      >
                        {c?.name?.EN}
                      </option>
                    ))}
                  </BForm.Select>

                  <ErrorMessage
                    component='div'
                    name='tenantGameCategoryId'
                    className='text-danger'
                  />
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Is Active <span className='text-danger'>*</span>
                  </BForm.Label>
                </Col>
                <Col>
                  <BForm.Check
                    type='checkbox'
                    className='mx-auto'
                    name='isActive'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.isActive}
                    defaultChecked={editIsActive}
                  />
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col className=''>
                  <BForm.Label>
                    Is Labeled Category <span className='text-danger'>*</span>
                  </BForm.Label>
                </Col>
                <Col>

                  <BForm.Check
                    type='checkbox'
                    className='mx-auto'
                    name='isLabeledCategory'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.isLabeledCategory}
                    defaultChecked={editIsLabeledCategory}
                  />
                </Col>
              </Row>

              {values.isLabeledCategory && <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Label Icon <span className='text-danger'>*</span>
                  </BForm.Label>

                  <BForm.Control
                    type='file'
                    name='labelIconUrl'
                    onChange={(event) => setFieldValue('labelIconUrl', event.target.files[0])}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='labelIconUrl'
                    className='text-danger'
                  />
                </Col>
              </Row>}

              {values.isLabeledCategory && !errors?.labelIconUrl &&
                (!editName
                  ? values?.labelIconUrl && (
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '300px', maxHeight: '300px' }}
                          src={
                            !!values?.labelIconUrl
                              ? URL.createObjectURL(values?.labelIconUrl)
                              : editLabelIconUrl
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '300px', maxHeight: '300px' }}
                          src={
                            !!values?.labelIconUrl
                              ? URL.createObjectURL(values?.labelIconUrl)
                              : editLabelIconUrl
                          }
                        />
                      </Col>
                    </Row>
                  )
                )
              }

              <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Logo <span className='text-danger'>*</span>
                  </BForm.Label>

                  <BForm.Control
                    type='file'
                    name='imageUrl'
                    onChange={(event) => setFieldValue('imageUrl', event.target.files[0])}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='imageUrl'
                    className='text-danger'
                  />

                </Col>
              </Row>

              {!errors?.imageUrl &&
                (!editName
                  ? values?.imageUrl && (
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '300px', maxHeight: '300px' }}
                          src={
                            values?.imageUrl &&
                            URL.createObjectURL(values?.imageUrl)
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row className='text-center'>
                      <Col>
                        <img
                          alt='not found'
                          className='mt-2'
                          style={{ maxWidth: '300px', maxHeight: '300px' }}
                          src={
                            values?.imageUrl
                              ? URL.createObjectURL(values?.imageUrl)
                              : editImageUrl
                          }
                        />
                      </Col>
                    </Row>
                  )
                )
              }

            </Modal.Body>

            <div className='mt-4'>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => {
                    setCreateName({ EN: '' })
                    setLanguage('')
                    handleClose()
                  }}
                >
                  Cancel
                </Button>

                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateSubCategory
