import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import {
  Row,
  Col,
  Form as BForm,
  Button,
  InputGroup
} from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import DateRangePicker from '../../../../../components/DateRangePicker'
import { bonusType, checkLabels, daysLabels, wageringRequirementType } from './constants'
import Trigger from '../../../../../components/OverlayTrigger'
import ReactQuill from 'react-quill'
import { useSelector, useDispatch } from 'react-redux'
import { getloyaltyLevel } from '../../../../../utils/apiCalls'
import { getTenantLanguagesStart } from '../../../../../store/redux-slices/bonus'
import { safeStringify } from '../../../../../utils/helpers'
import { getSuperAdminAggregatorsStart } from '../../../../../store/redux-slices/superAdminCasinoManagement'

const GeneralForm = ({
  isEdit,
  isClone,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  state,
  setState,
  preview,
  handleImagePreview,
  bonusDetail,
  clone = false,
  navigate,
  clientsFilterData,
  setSelectedClient,
  portalOptions,
  getBalanceBonusesOptions,
  isTenant,
  setGameIds,
  wageringTemplateList,
  setEnableSubmit,
  appliedBonusOptions,
  data,
  setData,
  setCountryData,
  setPrepaidIds
}) => {
  const dispatch = useDispatch()
  const { loyaltyLevel } = useSelector(state => state.superAdminSettings)
  const { aggregators } = useSelector(state => state.superAdminCasino)

  async function getLoyalty(option) {
    await getloyaltyLevel({ isTenant, tenantId: option?.value })
      .then(response => {
        const data = response.data.data.loyaltyLevel
        const newLoyaltyLevel = []
        for (const level in data) {
          const obj = data[level]
          newLoyaltyLevel.push(values?.bonusType === 'winBoost'
            ? {
              level: obj.level,
              startPoint: obj.startPoint,
              endPoint: obj.endPoint,
              bonusPercentage: (obj.cashback_multiplier * 100).toFixed(2),
              maxWinAmount: obj?.maxWinAmount || ''
            }
            : {
              level: obj.level,
              startPoint: obj.startPoint,
              endPoint: obj.endPoint,
              bonusPercentage: (obj.cashback_multiplier * 100).toFixed(2),
              cashback_multiplier: values?.wageringMultiplier,
              maxBonusThreshold: obj?.maxBonusThreshold || '',
              maxWinAmount: obj?.maxWinAmount || ''
            })
        }
        setFieldValue('loyaltyLevel', newLoyaltyLevel)
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    if (values.bonusType === 'balance' && values.tenantIds?.[0]) {
      if (values.tenantIds) {
        getBalanceBonusesOptions(values.tenantIds?.[0].value)
      }
    }
  }, [values.tenantIds, values.bonusType])

  useEffect(() => {
    if ((isEdit) && appliedBonusOptions?.[0] && appliedBonusOptions?.[0]?.label?.EN !== '') {
      setFieldValue('appliedBonusId', appliedBonusOptions.filter(
        (opt) => JSON.parse(opt.value) === JSON.parse(bonusDetail?.appliedBonusId)
      ))
    }
  }, [appliedBonusOptions])

  useEffect(() => {
    if (bonusDetail?.gameIds && setGameIds && !bonusDetail?.other?.hub88PrepaidDetails?.length) {
      setGameIds(bonusDetail?.gameIds)
    }
  }, [bonusDetail])

  useEffect(() => {
    dispatch(getTenantLanguagesStart({
      isTenant,
      tenantId: values?.tenantIds ? safeStringify(values?.tenantIds?.map((id) => id.value)) : '[]'
    }))
  }, [values?.tenantIds])

  // Get Aggregators
  useEffect(() => {
    if (values?.bonusType?.includes('freespins') && values?.tenantIds?.length) {
      dispatch(getSuperAdminAggregatorsStart({
        limit: '',
        pageNo: '',
        search: '',
        tenantId: values?.tenantIds?.[0]?.value
      }))
    }
  }, [values?.tenantIds, values?.bonusType])

  const [err, setErr] = useState('')
  const [errDesc, setErrDesc] = useState('')
  const [errPromoOverview, setErrPromoOverview] = useState('')

  return (
    <>
      <Row className=' mt-3 mb-3'>

        {/* Title */}
        <Col sm={4} className='mb-3'>
          <label
            htmlFor='promotionTitle'
            className={
              touched.promotionTitle && errors.promotionTitle
                ? 'text-danger'
                : ''
            }
          >
            Promotion Title<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.promotionTitle && errors.promotionTitle
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='promotionTitle'
              placeholder='Enter Promotion Title'
              defaultValue={values.promotionTitle}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                setData({
                  ...data,
                  promoTitle: {
                    ...data?.promoTitle,
                    EN: e.target.value
                  }
                })
                handleChange(e)
              }}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='promotionTitle'
            className='text-danger'
          />
        </Col>

        {/* Bonus Type */}
        <Col sm={4} className='mb-3'>
          <label
            htmlFor='bonusType'
            className={
              touched.bonusType && errors.bonusType ? 'text-danger' : ''
            }
          >
            Bonus Type<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.bonusType && errors.bonusType
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Select
              name='bonusType'
              disabled={isEdit || isClone}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e)
                setGameIds([])
                if (e.target.value === 'freespins' || e.target.value === 'cashfreespins') {
                  setFieldValue('wageringRequirementType', 'bonus')
                  setFieldValue('wageringMultiplier', 0)
                  setFieldValue('depositBonusPercent', 1)
                }
                if (e.target.value === 'freespins') {
                  setFieldValue('isSticky', true)
                } else {
                  setFieldValue('isSticky', false)
                }
                setFieldValue('currency', {
                  EUR: {
                    maxBonusThreshold: '',
                    minDeposit: '',
                    maxWinAmount: '',
                    zeroOutThreshold: '',
                    minBalance: '',
                    maxBetAllowed: ''
                  }
                })
                setEnableSubmit(false)
                setFieldValue('tenantIds', [])
                setFieldValue('visibleInPromotions', false)
                setFieldValue('wageringRequirementType', 'bonus')
              }}
              value={values.bonusType}
            >
              <option value='' disabled key=''>
                Select Bonus Type
              </option>
              {bonusDetail
                ? <option>{(bonusDetail?.bonusType === 'freespins' && !bonusDetail?.isSticky) ? 'CASH FREESPINS' : bonusType.find(val => val.value === bonusDetail?.bonusType)?.label}</option>
                : bonusType.map((item) => {
                  return (
                    <option key={`bonusType ${item.id}`} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
            </BForm.Select>
          </InputGroup>
          <ErrorMessage
            component='div'
            name='bonusType'
            className='text-danger'
          />
        </Col>

        {/* Owner */}
        {!isTenant &&
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='adminId'
              className={touched.adminId && errors.adminId ? 'text-danger' : ''}
            >
              Owner<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.adminId && errors.adminId ? 'border border-danger' : ''
              }
            >
              <BForm.Select
                disabled={isEdit}
                name='adminId'
                value={values.adminId}
                onChange={(e) => {
                  handleChange(e)
                  setSelectedClient(e.target.value)
                  setFieldValue('tenantIds', [])
                  setEnableSubmit(false)
                  setFieldValue('wageringTemplateId', wageringTemplateList?.[0]?.wageringTemplateId)
                }}
              >
                <option value='' disabled key=''>
                  Select Owner
                </option>
                {clientsFilterData?.rows?.map((admin) => (
                  <option value={admin.adminUserId} key={admin.adminUserId}>
                    {`${admin.email} (${admin?.firstName} ${admin?.lastName})`}
                  </option>
                ))}
              </BForm.Select>
            </InputGroup>
            <ErrorMessage
              component='div'
              name='adminId'
              className='text-danger'
            />
          </Col>}

        {/* Tenants */}
        <Col sm={4} className='mb-3'>
          <label
            htmlFor='tenantIds'
            className={
              touched.tenantIds && errors.tenantIds ? 'text-danger' : ''
            }
          >
            {isEdit || isClone ? 'Portal' : 'Portals'}
            <span className='text-danger'> *</span>
          </label>
          <Select
            isDisabled={isEdit}
            isMulti={!['balance', 'deposit', 'wagering', 'winBoost', 'freespins', 'cashfreespins', 'zero_deposit'].includes(values?.bonusType)}
            isClearable={false}
            name='tenantIds'
            value={values.tenantIds}
            options={portalOptions}
            className={
              'basic-multi-select' && touched.tenantIds && errors.tenantIds
                ? 'border border-danger'
                : ''
            }
            classNamePrefix='select'
            onChange={(option, e) => {
              setFieldValue('aggregatorId', '')
              setEnableSubmit(false)
              if (['deposit', 'wagering', 'winBoost', 'balance', 'freespins', 'cashfreespins', 'zero_deposit'].includes(values.bonusType)) {
                setFieldValue('tenantIds', [option])
                if (['deposit', 'wagering', 'winBoost'].includes(values.bonusType)) {
                  getLoyalty(option)
                }
              } else {
                setFieldValue('tenantIds', option)
              }
            }}
          />
          <ErrorMessage
            component='div'
            name='tenantIds'
            className='text-danger'
          />
        </Col>

        {/* Aggregator */}
        {values?.bonusType?.includes('freespins') &&
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='aggregatorId'
              className={
                touched.aggregatorId && errors.aggregatorId ? 'text-danger' : ''
              }
            >
              Aggregator<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.aggregatorId && errors.aggregatorId
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Select
                name='aggregatorId'
                disabled={isEdit}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={(e) => {
                  setGameIds([])
                  setPrepaidIds([])
                  handleChange(e)
                }}
                value={values.aggregatorId}
              >
                <option value='' disabled key=''>
                  Select Aggregator
                </option>
                {aggregators?.rows?.map((item) => {
                  return (
                    <option key={`aggregatorId ${item.name}`} value={item.masterGameAggregatorId}>
                      {item.name}
                    </option>
                  )
                })}
              </BForm.Select>
            </InputGroup>
            <ErrorMessage
              component='div'
              name='aggregatorId'
              className='text-danger'
            />
          </Col>}

        {/* Applied Bonus */}
        {values?.bonusType === 'balance' && (
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='appliedBonusId'
              className={
                touched.appliedBonusVal && errors.appliedBonusVal
                  ? 'text-danger'
                  : ''
              }
            >
              Applied Bonus<span className='text-danger'> *</span>
            </label>
            <Select
              isDisabled={isEdit || values?.tenantIds?.length < 1}
              isClearable={false}
              name='appliedBonusVal'
              value={values?.appliedBonusId}
              options={appliedBonusOptions}
              className={
                'basic-multi-select' && touched.appliedBonusVal && errors.appliedBonusVal
                  ? 'border border-danger'
                  : ''
              }
              classNamePrefix='select'
              onChange={(option, e) => {
                setFieldValue('appliedBonusId', option)
                setFieldValue('appliedBonusVal', option.value)
                setCountryData(option.country)
              }}
            />
            <ErrorMessage
              component='div'
              name='appliedBonusVal'
              className='text-danger'
            />
          </Col>
        )}
        {(values.bonusType === 'match' || values.bonusType === 'balance') && (
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='depositBonusPercent'
              className={
                touched.depositBonusPercent && errors.depositBonusPercent
                  ? 'text-danger'
                  : ''
              }
            >
              {values.bonusType === 'balance'
                ? 'Min Balance Percentage'
                : 'Bonus Percentage'}
              <span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.depositBonusPercent && errors.depositBonusPercent
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Control
                name='depositBonusPercent'
                disabled={isEdit && bonusDetail?.claimedCount}
                type='number'
                min={1}
                placeholder={
                  values.bonusType === 'balance'
                    ? 'Enter Min Balance Percentage'
                    : 'Enter Bonus Percentage'
                }
                defaultValue={values.depositBonusPercent}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputGroup>
            <ErrorMessage
              component='div'
              name='depositBonusPercent'
              className='text-danger'
            />
          </Col>
        )}

        {!['deposit', 'wagering', 'winBoost', 'zero_deposit'].includes(values.bonusType) &&
          <Col sm={4} className='mb-3'>
            <label
              className={
                touched.bonusType && errors.bonusType ? 'text-danger' : ''
              }
            >
              Bonus Validity<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.bonusType && errors.bonusType
                  ? 'border border-danger'
                  : ''
              }
            >
              <DateRangePicker
                width='auto'
                disabled={isEdit && bonusDetail?.claimedCount}
                state={state}
                setState={setState}
                size='md'
                bonus
              />
            </InputGroup>
          </Col>}

        {['deposit', 'wagering', 'winBoost', 'zero_deposit'].includes(values.bonusType) &&
          <Col sm={4} className='mb-3'>
            <label
              className={
                touched.timePeriod && errors.timePeriod ? 'text-danger' : ''
              }
            >
              Time Period<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.timePeriod && errors.timePeriod
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Select
                name='timePeriod'
                disabled={bonusDetail?.claimedCount}
                value={values?.timePeriod}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option key='daily' value='1'>Daily</option>
                <option key='weekly' value='7'>Weekly</option>
                <option key='monthly' value='30'>Monthly</option>
              </BForm.Select>
            </InputGroup>
          </Col>}

        {(values.bonusType !== 'balance')
          ? (
            (
              <>
                {(values.bonusType === 'freespins' || values?.bonusType === 'cashfreespins') && (values?.bonusType?.toLowerCase()?.includes('freespin')
                  ? !aggregators?.rows?.find(({ masterGameAggregatorId }) => masterGameAggregatorId === parseInt(values?.aggregatorId))?.name?.includes('hub88')
                  : true) && (
                    <Col sm={4} className='mb-3'>
                      <label
                        htmlFor='quantity'
                        className={
                          touched.quantity && errors.quantity ? 'text-danger' : ''
                        }
                      >
                        Quantity<span className='text-danger'> *</span>
                      </label>
                      <InputGroup
                        className={
                          touched.quantity && errors.quantity
                            ? 'border border-danger'
                            : ''
                        }
                      >
                        <BForm.Control
                          name='quantity'
                          type='number'
                          min={1}
                          disabled={isEdit && bonusDetail?.claimedCount}
                          placeholder='Enter Quantity'
                          defaultValue={values.quantity}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='quantity'
                        className='text-danger'
                      />
                    </Col>)}
                {!['promotion', 'cashfreespins', 'freespins', 'winBoost'].includes(values.bonusType)
                  ? (
                    <Col sm={4} className='mb-3'>
                      <label
                        htmlFor='wageringMultiplier'
                        className={
                          touched.wageringMultiplier && errors.wageringMultiplier
                            ? 'text-danger'
                            : ''
                        }
                      >
                        Wagering Multiplier<span className='text-danger'> *</span>
                      </label>
                      <InputGroup
                        className={
                          touched.wageringMultiplier && errors.wageringMultiplier
                            ? 'border border-danger'
                            : ''
                        }
                      >
                        <BForm.Control
                          name='wageringMultiplier'
                          type='number'
                          min={0}
                          disabled={isEdit && bonusDetail?.claimedCount}
                          placeholder='Enter Wagering Multiplier'
                          value={values.wageringMultiplier}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e)
                            if (values?.bonusType === 'deposit' || values?.bonusType === 'wagering' || values?.bonusType === 'winBoost') {
                              const newLoyaltyLevel = []
                              for (const level in loyaltyLevel) {
                                const obj = loyaltyLevel[level]
                                newLoyaltyLevel.push(values?.bonusType === 'winBoost'
                                  ? {
                                    level: obj.level,
                                    startPoint: obj.startPoint,
                                    endPoint: obj.endPoint,
                                    bonusPercentage: (values?.other?.[level]?.bonusPercentage * 100).toFixed(2),
                                    maxWinAmount: values?.other?.[level]?.maxWinAmount
                                  }
                                  : {
                                    level: obj.level,
                                    startPoint: obj.startPoint,
                                    endPoint: obj.endPoint,
                                    bonusPercentage: (obj.cashback_multiplier * 100).toFixed(2),
                                    cashback_multiplier: e.target.value,
                                    maxBonusThreshold: obj?.maxBonusThreshold || values?.loyaltyLevel?.[level]?.maxBonusThreshold || '',
                                    maxWinAmount: obj?.maxWinAmount || values?.loyaltyLevel?.[level]?.maxWinAmount || ''
                                  })
                              }
                              setFieldValue('loyaltyLevel', newLoyaltyLevel)
                            }
                          }}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='wageringMultiplier'
                        className='text-danger'
                      />
                    </Col>)
                  : (values?.isSticky &&
                    <Col sm={4} className='mb-3'>
                      <label
                        htmlFor='wageringMultiplier'
                        className={
                          touched.wageringMultiplier && errors.wageringMultiplier
                            ? 'text-danger'
                            : ''
                        }
                      >
                        Wagering Multiplier<span className='text-danger'> *</span>
                      </label>
                      <InputGroup
                        className={
                          touched.wageringMultiplier && errors.wageringMultiplier
                            ? 'border border-danger'
                            : ''
                        }
                      >
                        <BForm.Control
                          name='wageringMultiplier'
                          type='number'
                          min={0}
                          disabled={isEdit && bonusDetail?.claimedCount}
                          placeholder='Enter Wagering Multiplier'
                          value={values.wageringMultiplier}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='wageringMultiplier'
                        className='text-danger'
                      />
                    </Col>)}
              </>
            )
          )
          : null}
        {!['balance', 'freespins', 'cashfreespins', 'promotion', 'winBoost', 'manual'].includes(values?.bonusType)
          ? (
            <Col sm={4} className='mb-3'>
              <Row>
                <Col>
                  <label
                    htmlFor='isSticky'
                    className={
                      touched.isSticky && errors.isSticky ? 'text-danger' : ''
                    }
                  >
                    Sticky<span className='text-danger'> *</span>
                  </label>
                  <InputGroup
                    className={
                      touched.isSticky && errors.isSticky
                        ? 'border border-danger'
                        : ''
                    }
                  >
                    <BForm.Select
                      name='isSticky'
                      value={values.isSticky}
                      onInput={handleChange}
                      onBlur={handleBlur}
                      disabled={['deposit', 'wagering', 'zero_deposit'].includes(values?.bonusType) || (isEdit && bonusDetail?.claimedCount)}
                      onChange={(e) => {
                        handleChange(e)
                        setFieldValue('wageringRequirementType', e.target.value === 'true' ? 'bonusdeposit' : 'bonus')
                      }}
                    >
                      <option value='false'>No</option>
                      <option value='true'>Yes</option>
                    </BForm.Select>
                  </InputGroup>
                </Col>
                <Col>
                  <label
                    htmlFor='wageringRequirementType'
                    className={
                      touched.wageringRequirementType && errors.wageringRequirementType ? 'text-danger' : ''
                    }
                  >
                    Wagering Type<span className='text-danger'> *</span>
                  </label>
                  <InputGroup
                    className={
                      touched.wageringRequirementType && errors.wageringRequirementType
                        ? 'border border-danger'
                        : ''
                    }
                  >
                    <BForm.Select
                      name='wageringRequirementType'
                      disabled
                      placeholder='Choose Wagering Type'
                      defaultValue={
                        wageringRequirementType.filter(
                          (item) => item.id === values.wageringRequirementType
                        )[0]?.value
                      }
                      value={values.wageringRequirementType}
                      onInput={handleChange}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {wageringRequirementType.map((item) => {
                        return (
                          <option key={`wagering ${item.value}`} value={item.value}>
                            {item.label}
                          </option>
                        )
                      })}
                    </BForm.Select>
                  </InputGroup>
                  <ErrorMessage
                    component='div'
                    name='wageringRequirementType'
                    className='text-danger'
                  />
                </Col>
              </Row>
            </Col>
          )
          : values?.isSticky && (
            <Col sm={4} className='mb-3'>
              <label
                htmlFor='wageringRequirementType'
                className={
                  touched.wageringRequirementType && errors.wageringRequirementType ? 'text-danger' : ''
                }
              >
                Wagering Type<span className='text-danger'> *</span>
              </label>
              <InputGroup
                className={
                  touched.wageringRequirementType && errors.wageringRequirementType
                    ? 'border border-danger'
                    : ''
                }
              >
                <BForm.Select
                  name='wageringRequirementType'
                  disabled
                  placeholder='Choose Wagering Type'
                  defaultValue={
                    wageringRequirementType.filter(
                      (item) => item.id === values.wageringRequirementType
                    )[0]?.value
                  }
                  value={values.wageringRequirementType}
                  onInput={handleChange}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {wageringRequirementType.map((item) => {
                    return (
                      <option key={`wagering ${item.value}`} value={item.value}>
                        {item.label}
                      </option>
                    )
                  })}
                </BForm.Select>
              </InputGroup>
              <ErrorMessage
                component='div'
                name='wageringRequirementType'
                className='text-danger'
              />
            </Col>
          )}

        {!['promotion', 'winBoost'].includes(values?.bonusType) &&
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='daysToClear'
              className={
                touched.daysToClear && errors.daysToClear ? 'text-danger' : ''
              }
            >
              Days To Clear<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.daysToClear && errors.daysToClear
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Control
                name='daysToClear'
                type='number'
                min={1}
                disabled={isEdit && bonusDetail?.claimedCount}
                placeholder='Enter Days To Clear'
                value={values.daysToClear}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputGroup>
            <ErrorMessage
              component='div'
              name='daysToClear'
              className='text-danger'
            />
          </Col>}
        {(values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins') && !aggregators?.rows?.find(({ masterGameAggregatorId }) => masterGameAggregatorId === parseInt(values?.aggregatorId))?.name?.includes('hub88') &&
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='betLevel'
              className={
                touched.betLevel && errors.betLevel ? 'text-danger' : ''
              }
            >
              Bet Level<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.betLevel && errors.betLevel
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Control
                name='betLevel'
                type='number'
                min={1}
                disabled={isEdit && bonusDetail?.claimedCount}
                placeholder='Enter Bet Level'
                value={values.betLevel}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputGroup>
            <ErrorMessage
              component='div'
              name='betLevel'
              className='text-danger'
            />
          </Col>}
      </Row>
      <Row className='mb-3'>
        {checkLabels(values.bonusType).map(({ label, value, message }) => {
          return (
            <Col
              key={`ffddChecked ${label}`}
              sm={3}
              className='d-flex justify-content-start mb-3'
            >
              <Trigger message={message}>
                <Col>
                  <label htmlFor={value}>
                    {label}
                  </label>
                </Col>
              </Trigger>
              <Col sm={4} className='mb-3'>
                <BForm.Check
                  name={value}
                  type='switch'
                  disabled={isEdit && bonusDetail?.claimedCount}
                  checked={values[value]}
                  onChange={(e) => {
                    handleChange(e)
                    if (label === 'Visible In Promotions') {
                      setFieldValue('validOnDays', [])
                    }
                  }}
                />
              </Col>
            </Col>
          )
        })}

        {!['deposit', 'wagering', 'zero_deposit'].includes(values?.bonusType) &&
          <Col
            key='ffddChecked Show validity'
            sm={3}
            className='d-flex justify-content-start mb-3'
          >
            <Trigger message='If true, bonus validity will be hidden to user.'>
              <Col>
                <label htmlFor='showBonusValidity'>
                  Show Validity
                </label>
              </Col>
            </Trigger>
            <Col sm={4} className='mb-3'>
              <BForm.Check
                name='showBonusValidity'
                type='switch'
                checked={values?.showBonusValidity}
                onChange={(e) => {
                  setFieldValue('showBonusValidity', e.target.checked)
                }}
              />
            </Col>
          </Col>}
      </Row>
      {values?.visibleInPromotions && values?.bonusType !== 'promotion' &&
        <Row className='mb-3'>
          <label
            htmlFor='days'
            className={
              'mb-2' && touched.validOnDays && errors.validOnDays
                ? 'text-danger d-flex'
                : 'd-flex'
            }
          >
            Valid on Days
            <span className='text-danger'> * </span>&nbsp;
            <BForm.Check
              name='select-all'
              type='checkbox'
              disabled={isEdit && bonusDetail?.claimedCount}
              checked={values?.validOnDays?.length === 7}
              onChange={(e) => {
                if (e.target.checked) {
                  setFieldValue('validOnDays', [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                  ])
                } else {
                  setFieldValue(
                    'validOnDays', [])
                }
              }}
            />
          </label>
          {daysLabels.map((days) => {
            return (
              <Col className='d-flex  mb-3' key={days}>
                <Col className='d-flex'>
                  <BForm.Check
                    name={days}
                    type='checkbox'
                    disabled={isEdit && bonusDetail?.claimedCount}
                    checked={
                      values?.validOnDays && values?.validOnDays.includes(days)
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        values?.validOnDays?.length
                          ? setFieldValue('validOnDays', [
                            ...values.validOnDays,
                            e.target.name
                          ])
                          : setFieldValue('validOnDays', [
                            e.target.name
                          ])
                      } else {
                        setFieldValue(
                          'validOnDays',
                          values.validOnDays.filter(
                            (days) => days !== e.target.name
                          )
                        )
                      }
                    }}
                  />
                  <label className='mx-2' htmlFor={days}>
                    {days}
                  </label>
                </Col>
              </Col>
            )
          })}
        </Row>}

      <Row className='mb-3'>
        <Col>
          <label
            htmlFor='promotionalOverview'
            className={
              ((touched?.promotionalOverview && (errPromoOverview || errors.promotionalOverview))) ? 'text-danger' : ''
            }
          >
            Promotion Overview<span className='text-danger'> *</span>
          </label>
          <ReactQuill
            name='promotionalOverview'
            placeholder='Enter Promotion Overview'
            value={values.promotionalOverview}
            onChange={(e) => {
              setFieldValue('promotionalOverview', e.replace(/\s/g, '&nbsp;'))
              setData({
                ...data,
                promoOverview: {
                  ...data?.promoOverview,
                  EN: e.replace(/\s/g, '&nbsp;')
                }
              })
            }}
            onKeyDown={() => setErrPromoOverview('')}
          />

          {errPromoOverview &&
            <div className='text-danger'>{errPromoOverview}</div>}

          {!errPromoOverview &&
            <ErrorMessage
              component='div'
              name='promotionalOverview'
              className='text-danger'
            />}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <label
            htmlFor='termCondition'
            className={
              ((touched?.termCondition && (err || errors.termCondition))) ? 'text-danger' : ''
            }
          >
            Terms & Conditions<span className='text-danger'> *</span>
          </label>
          <ReactQuill
            name='termCondition'
            placeholder='Enter Terms and Conditions'
            value={values.termCondition}
            onChange={(e) => {
              setFieldValue('termCondition', e.replace(/\s/g, '&nbsp;'))
              setData({
                ...data,
                terms: {
                  ...data?.terms,
                  EN: e.replace(/\s/g, '&nbsp;')
                }
              })
            }}
            onKeyDown={() => setErr('')}
          />

          {err &&
            <div className='text-danger'>{err}</div>}

          {!err &&
            <ErrorMessage
              component='div'
              name='termCondition'
              className='text-danger'
            />}
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <label
            htmlFor='description'
            className={
              ((touched?.description && (errDesc || errors.description))) ? 'text-danger' : ''
            }
          >
            Description<span className='text-danger'> *</span>
          </label>
          <ReactQuill
            name='description'
            placeholder='Enter Description'
            value={values.description}
            onChange={(e) => {
              setFieldValue('description', e.replace(/\s/g, '&nbsp;'))
              setData({
                ...data,
                desc: {
                  ...data?.desc,
                  EN: e.replace(/\s/g, '&nbsp;')
                }
              })
            }}
            onKeyDown={() => setErrDesc('')}
          />

          {errDesc &&
            <div className='text-danger'>{errDesc}</div>}

          {!errDesc &&
            <ErrorMessage
              component='div'
              name='description'
              className='text-danger'
            />}
        </Col>
      </Row>

      <Row>
        <Col>
          <label
            htmlFor='bonusImage'
            className={
              touched.bonusImage && errors.bonusImage ? 'text-danger' : ''
            }
          >
            Bonus Image<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.bonusImage && errors.bonusImage
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='bonusImage'
              type='file'
              placeholder='Image'
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue('bonusImage', event.currentTarget.files[0])
                handleImagePreview(event)
              }}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='bonusImage'
            className='text-danger'
          />
        </Col>
        <Col>
          {!clone &&
            !errors.bonusImage &&
            (preview?.image_preview
              ? (
                <img
                  src={preview?.image_preview}
                  width='150'
                  height='150'
                  className='mt-2 border-0'
                  alt='bonus-img'
                />
              )
              : (
                bonusDetail &&
                bonusDetail?.imageUrl && !isClone && (
                  <img
                    src={bonusDetail?.imageUrl}
                    width='150'
                    height='150'
                    alt='bonus-img'
                    className='mt-2 border-0'
                  />
                )
              ))}
        </Col>
      </Row>
      <div className='mt-4 d-flex justify-content-between'>
        <Button
          variant='outline-warning'
          onClick={() => navigate(-1)}
          className='ml-2'
        >
          Cancel
        </Button>
        <Button
          className='ml-2'
          variant='outline-success'
          onClick={() => {
            if (!errors.termCondition || !errors.desc || !errors.promoOverview) {
              if (touched?.termCondition && !values?.termCondition?.replace(/<[^>]+>/g, '')?.length) {
                setErr('Terms and Conditions Required')
                setFieldValue('termCondition', '')
              } else if (touched?.description && !values?.description?.replace(/<[^>]+>/g, '')?.length) {
                setErr('')
                setErrDesc('Description Required')
                setFieldValue('description', '')
              } else if (touched?.promotionalOverview && !values?.promotionalOverview?.replace(/<[^>]+>/g, '')?.length) {
                setErrDesc('')
                setErrPromoOverview('Promotion Overview Required')
                setFieldValue('promotionalOverview', '')
              } else {
                setErrPromoOverview('')
                handleSubmit()
              }
            } else {
              setErr('Terms and Conditions Required')
            }
          }}

        >
          Next
        </Button>
      </div>
    </>
  )
}
export default GeneralForm
