import React from 'react'
import useUserData from './useUserData'
import Overview from './components/Overview'
import Comments from './components/Comments'
import Settings from './components/Settings'
import useUserDetails from './useUserDetails'
import ConfirmationModal, {
  DuplicateUserModal,
  ResetPasswordEmailModal,
  ResetUserPasswordModal,
  VerifyEmailModal
} from '../../../components/ConfirmationModal'
import SendEmail from './components/SendEmail'
import EmailLogs from './components/EmailLogs'
import AuditLogs from './components/AuditLogs'
import GameReport from './components/GameReport'
import UserWallet from './components/UserWallet'
import YourBonuses from './components/YourBonuses'
import Preloader from '../../../components/Preloader'
import EditUserModal from './components/EditUserModal'
import AffiliateForm from './components/AffiliateForm'
import DisableReason from './components/DisableReason'
import ManageMoney from '../../../components/ManageMoney'
import AllTransactions from './components/AllTransaction'
import RestrictMethods from './components/RestrictMethods'
import useCheckPermission from '../../../utils/checkPermission'
import CasinoTransactions from './components/CasinoTransactions'
import TransactionBanking from './components/TransactionBanking'
import PaymentModal from '../../../components/ConfirmationModal/PaymentModal'
import { Tabs, Tab, Row, Col, Breadcrumb } from '@themesberg/react-bootstrap'
import FreeSpinBonusModal from '../../../components/FreeSpinBonusModal/FreeSpinBonusModal'
import {
  AddAffiliateModal,
  ConfirmUserAsInternalModal,
  ConfirmUserAsStreamerModal,
  RemoveAffiliateModal,
  TagModal,
  UserBonusIssueModal,
  VerifyPhoneModal
} from './components/Modals'
import JackpotContribution from '../Dashboard/components/JackpotContribution'
import UserSessionData from './components/UserSessionData'
import { checkIsTenant } from '../../../utils/constants'

const UserDetails = ({ isTenant = false }) => {
  const {
    loading,
    navigate,
    userData,
    selectedTab,
    emailLoading,
    setSelectedTab,

    // Audit Logs
    auditsPageNo,
    setAuditsPageNo,
    setAuditsLimit,
    auditsLimit,
    auditsSearch,
    setAuditsSearch,
    auditsState,
    setAuditsState,

    // Session Logs
    sessionLogsPageNo,
    setSessionLogsPageNo,
    setSessionLogsLimit,
    sessionLogsLimit,
    sessionLogsSearch,
    setSessionLogsSearch,
    sessionLogsState,
    setSessionLogsState,

    // Affiliate Form
    affiliateState,
    setAffiliateState,

    // Email Logs
    emailLimit,
    setEmailLimit,
    emailRole,
    setEmailRole,
    emailPageNo,
    setEmailPageNo,
    emailSearch,
    setEmailSearch,

    // Comments
    commentsLimit,
    setCommentsLimit,
    commentsPageNo,
    setCommentsPageNo,
    commentsSearch,
    setCommentsSearch,
    commentsRole,
    setCommentsRole,
    commentsStatus,
    setCommentsStatus,

    // Your Bonuses
    bonusLimit,
    setBonusLimit,
    bonusPage,
    setBonusPage,
    bonusStatus,
    setBonusStatus,
    bonusType,
    setBonusType,

    // Game Report
    gameSelectedTab,
    setGameSelectedTab,
    gameDateOptions,
    setGameDateOptions,
    gameLimit,
    setGameLimit,
    gameState,
    setGameState,

    // Phone Verification
    verifyPhoneModal,
    setVerifyPhoneModal,
    handleVerifyPhoneModal,

    // Overview
    reloadUserStatus,
    setActiveInactiveModal,
    isActiveInactivemodalVisible,
    verifyEmailModal,
    setVerifyEmailModal,
    isInternalModal,
    setIsInternalModal,
    isStreamerModal,
    setIsStreamerModal,
    updateUserTags,
    superAdminRoleId,
    setShowTagModal,
    showTagModal,
    addTag,
    updateTags,
    tagOptions,
    setTagOptions,
    selectedTag,
    setSelectedTag,
    duplicateUsersLimit,
    setDuplicateUsersLimit,
    duplicateUsersPageNo,
    setDuplicateUsersPageNo,
    showManageMoneyModal,
    setShowManageMoneyModal,
    showPaymentModal,
    setShowPaymentModal,
    userPasswordModal,
    setUserPasswordModal,
    passwordEmailModal,
    setPasswordEmailModal,
    handleResetPasswordEmail,
    handleResetUserPassword,
    showAddAffiliate,
    setShowAddAffiliate,
    showRemoveAffiliate,
    setShowRemoveAffiliate,
    addAffiliateHandler,
    removeAffiliateHandler,
    addUserAffiliateLoading,
    updateUserAffiliateLoading,
    freespinModal,
    setFreeSpinModal,
    bonusAmount,
    setBonusAmount,
    showModal,
    setShowModal,
    selectedBonus,
    setSelectedBonus,
    issueBonusHandler,
    bonusDetail,
    bonusList,
    updateUserStatus,
    handleVerifyEmail,
    editUserModal,
    setEditUserModal,
    showDuplicateModal,
    setShowDuplicateModal,
    getDuplicateUsers,
    duplicateUsers
  } = useUserDetails({ isTenant })

  const { isHidden } = useCheckPermission()
  const {
    moreInfo,
    basicInfo,
    adminPermissions,
    userCurrentStatus,
    userStatusLoading
  } = useUserData({ userData })

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate(`/${checkIsTenant() ? 'tenant' : 'super-admin'}/users`)}>
                  Players
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Player:- &nbsp;
                  {userData && userData?.firstName}{' '}
                  {userData && userData?.lastName}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col className='d-flex'>
                <h3>Player:&nbsp;</h3>
                <h3>
                  <div className='d-flex'>
                    {userData && userData?.firstName}{' '}
                    {userData && userData?.lastName}
                  </div>
                </h3>
              </Col>
            </Row>
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light'
              mountOnEnter
            >
              <Tab eventKey='overview' title='Overview' mountOnEnter>
                <Row className='mt-5'>
                  <Overview
                    user={userData}
                    addTag={addTag}
                    isHidden={isHidden}
                    moreInfo={moreInfo}
                    basicInfo={basicInfo}
                    isTenant={isTenant}
                    loading={userStatusLoading}
                    setShowModal={setShowModal}
                    duplicateUsers={duplicateUsers}
                    setEditModal={setEditUserModal}
                    userLimits={userData?.userLimit}
                    adminPermissions={adminPermissions}
                    reloadUserStatus={reloadUserStatus}
                    setFreeSpinModal={setFreeSpinModal}
                    getDuplicateUsers={getDuplicateUsers}
                    userCurrentStatus={userCurrentStatus}
                    setUserPassword={setUserPasswordModal}
                    setIsInternalModal={setIsInternalModal}
                    setIsStreamerModal={setIsStreamerModal}
                    setPasswordEmail={setPasswordEmailModal}
                    setVerifyPhoneModal={setVerifyPhoneModal}
                    setShowPaymentModal={setShowPaymentModal}
                    setShowAddAffiliate={setShowAddAffiliate}
                    setVerifyEmailModal={setVerifyEmailModal}
                    setActiveInactiveModal={setActiveInactiveModal}
                    setShowRemoveAffiliate={setShowRemoveAffiliate}
                    setShowManageMoneyModal={setShowManageMoneyModal}
                    addUserAffiliateLoading={addUserAffiliateLoading}
                    updateUserAffiliateLoading={updateUserAffiliateLoading}
                  />
                </Row>
              </Tab>

              <Tab eventKey='wallet' title='Wallet' mountOnEnter>
                <div className='mt-5'>
                  <Row className='mt-3 d-flex text-right'>
                    <UserWallet myUserData={userData} isTenant={isTenant} />
                  </Row>
                </div>
              </Tab>

              {!isHidden({ module: { key: 'CasinoTransaction', value: 'R' } }) && (
                <Tab
                  eventKey='casino-transactions'
                  title='Bet History'
                  mountOnEnter
                >
                  <CasinoTransactions isTenant={isTenant} />
                </Tab>
              )}

              {!isHidden({ module: { key: 'Transactions', value: 'R' } }) && (
                <Tab
                  eventKey='transactions-banking'
                  title='Transactions'
                  mountOnEnter
                >
                  <TransactionBanking isTenant={isTenant} />
                </Tab>
              )}

              <Tab eventKey='settings' title='KYC' mountOnEnter>
                <div className='mt-5'>
                  <Row className='mt-3 d-flex flex-row-reverse '>
                    {userData && <Settings isTenant={isTenant} />}
                  </Row>
                </div>
              </Tab>

              {!isHidden({ module: { key: 'GameReport', value: 'R' } }) && (
                <Tab eventKey='game-report' title='Game Report' mountOnEnter>
                  <div className='mt-5'>
                    <Row className='mt-3 d-flex flex-row-reverse '>
                      <GameReport
                        limit={gameLimit}
                        state={gameState}
                        userData={userData}
                        isTenant={isTenant}
                        setLimit={setGameLimit}
                        setState={setGameState}
                        selectedTab={gameSelectedTab}
                        dateOptions={gameDateOptions}
                        setSelectedTab={setGameSelectedTab}
                        setDateOptions={setGameDateOptions}
                      />
                    </Row>
                  </div>
                </Tab>
              )}

              {!isHidden({ module: { key: 'JackpotReport', value: 'R' } }) && (
                <Tab eventKey='jackpot-report' title='Jackpot Report' mountOnEnter>
                  <div className='mt-5'>
                    <Row className='mt-3 d-flex flex-row-reverse '>
                      <JackpotContribution
                        accordionState={{ JackpotReport: true }}
                        userData={userData}
                      />
                    </Row>
                  </div>
                </Tab>
              )}

              {!isHidden({ module: { key: 'Transactions', value: 'R' } }) && (
                <Tab
                  eventKey='allTransaction'
                  title='All Transactions'
                  mountOnEnter
                >
                  <div className='mt-5'>
                    <AllTransactions isTenant={isTenant} />
                  </div>
                </Tab>
              )}

              {!isHidden({ module: { key: 'Bonus', value: 'R' } }) && (
                <Tab eventKey='your-bonuses' title='Bonuses' mountOnEnter>
                  <div className='mt-5'>
                    <YourBonuses
                      page={bonusPage}
                      limit={bonusLimit}
                      isTenant={isTenant}
                      status={bonusStatus}
                      bonusType={bonusType}
                      setPage={setBonusPage}
                      setLimit={setBonusLimit}
                      setStatus={setBonusStatus}
                      setBonusType={setBonusType}
                      currencyCode={userData?.currencyCode}
                    />
                  </div>
                </Tab>
              )}

              {!isHidden({ module: { key: 'UserComment', value: 'R' } }) && (
                <Tab eventKey='comments' title='Comments' mountOnEnter>
                  <div className='mt-5'>
                    <Comments
                      isTenant={isTenant}
                      userData={userData}
                      role={commentsRole}
                      isHidden={isHidden}
                      limit={commentsLimit}
                      page={commentsPageNo}
                      search={commentsSearch}
                      status={commentsStatus}
                      setRole={setCommentsRole}
                      setLimit={setCommentsLimit}
                      setPage={setCommentsPageNo}
                      setSearch={setCommentsSearch}
                      setStatus={setCommentsStatus}
                    />
                  </div>
                </Tab>
              )}

              {!isHidden({ module: { key: 'Users', value: 'SM' } }) && (
                <Tab eventKey='sendemail' title='Send Email' mountOnEnter>
                  <div className='mt-5'>
                    <SendEmail
                      userData={userData}
                      isHidden={isHidden}
                      isTenant={isTenant}
                      loading={emailLoading}
                    />
                  </div>
                </Tab>
              )}

              <Tab eventKey='emailLogs' title='Email Logs' mountOnEnter>
                <div className='mt-5'>
                  <EmailLogs
                    role={emailRole}
                    limit={emailLimit}
                    isHidden={isHidden}
                    isTenant={isTenant}
                    pageNo={emailPageNo}
                    search={emailSearch}
                    setRole={setEmailRole}
                    setLimit={setEmailLimit}
                    setPageNo={setEmailPageNo}
                    setSearch={setEmailSearch}
                  />
                </div>
              </Tab>

              {!isHidden({ module: { key: 'Users', value: 'AT' } }) && (
                <Tab eventKey='auditLogs' title='Audit Logs' mountOnEnter>
                  <div className='mt-5'>
                    <AuditLogs
                      limit={auditsLimit}
                      isHidden={isHidden}
                      page={auditsPageNo}
                      state={auditsState}
                      search={auditsSearch}
                      setPage={setAuditsPageNo}
                      setLimit={setAuditsLimit}
                      setState={setAuditsState}
                      setSearch={setAuditsSearch}
                    />
                  </div>
                </Tab>
              )}

              {!isHidden({ module: { key: 'Users', value: 'SL' } }) && (
                <Tab eventKey='sessionLogs' title='Session Logs' mountOnEnter>
                  <div className='mt-5'>
                    <UserSessionData
                      user={userData}
                      isHidden={isHidden}
                      limit={sessionLogsLimit}
                      page={sessionLogsPageNo}
                      state={sessionLogsState}
                      search={sessionLogsSearch}
                      setPage={setSessionLogsPageNo}
                      setLimit={setSessionLogsLimit}
                      setState={setSessionLogsState}
                      setSearch={setSessionLogsSearch}
                    />
                  </div>
                </Tab>
              )}

              {!userData?.isInternalUser && userData?.affiliateStatus && !isHidden({ module: { key: 'Users', value: 'UA' } }) && (
                <Tab eventKey='affiliateData' title='Affiliate Data' mountOnEnter>
                  <div className='mt-5'>
                    <AffiliateForm
                      isHidden={isHidden}
                      userData={userData}
                      isTenant={isTenant}
                      state={affiliateState}
                      setState={setAffiliateState}
                    />
                  </div>
                </Tab>
              )}

              {!isHidden({ module: { key: 'CashierManagement', value: 'U' } }) && (
                <Tab eventKey='restrictMethods' title='Restrict Methods' mountOnEnter>
                  <div className='mt-5'>
                    <RestrictMethods
                      userData={userData}
                    />
                  </div>
                </Tab>
              )}
            </Tabs>
          </>
          )}

      {/* Active In-Active Modal */}
      {userData?.status === true || userData?.status === 'true'
        ? (
          <DisableReason
            show={isActiveInactivemodalVisible}
            handleClose={() => setActiveInactiveModal(false)}
            markUserStatusInactive={updateUserStatus}
            name={`${userData?.firstName} ${userData?.lastName} (${
            userData?.email || '-'
          })`}
          />
          )
        : (
          <ConfirmationModal
            show={isActiveInactivemodalVisible}
            setShow={setActiveInactiveModal}
            handleYes={() => {
              updateUserStatus()
              setActiveInactiveModal(false)
            }}
            active={!!userData?.status}
            name={`${userData?.firstName} ${userData?.lastName} (${
            userData?.email || '-'
          })`}
          />
          )}

      {/* Verify Email Modal */}
      {verifyEmailModal && (
        <VerifyEmailModal
          show={verifyEmailModal}
          setShow={setVerifyEmailModal}
          userData={userData}
          handleVerifyEmail={handleVerifyEmail}
        />
      )}

      {/* isInternal Modal */}
      <ConfirmUserAsInternalModal
        userData={userData}
        isInternalModal={isInternalModal}
        setIsInternalModal={setIsInternalModal}
        internalPlayerHandler={() => updateUserTags('Internal')}
      />

      {/* Streamer Modal */}
      <ConfirmUserAsStreamerModal
        userData={userData}
        isStreamerModal={isStreamerModal}
        setIsStreamerModal={setIsStreamerModal}
        markUserStreamer={() => updateUserTags('Streamer')}
      />

      {/* Manage tag */}
      {showTagModal && (
        <TagModal
          show={showTagModal}
          superAdminRoleId={superAdminRoleId}
          tagOptions={tagOptions}
          updateTags={updateTags}
          selectedTag={selectedTag}
          handleClose={setShowTagModal}
          setTagOptions={setTagOptions}
          setSelectedTag={setSelectedTag}
          name={`${userData?.firstName} ${userData?.lastName}`}
        />
      )}

      {/* Duplicate Users */}
      {showDuplicateModal && (
        <DuplicateUserModal
          show={showDuplicateModal}
          limit={duplicateUsersLimit}
          pageNo={duplicateUsersPageNo}
          setShow={setShowDuplicateModal}
          setLimit={setDuplicateUsersLimit}
          setPageNo={setDuplicateUsersPageNo}
        />
      )}

      {/* Manage Money */}
      {showManageMoneyModal && (
        <ManageMoney
          isTenant={isTenant}
          user={userData}
          show={showManageMoneyModal}
          handleClose={setShowManageMoneyModal}
          isHidden={isHidden}
        />
      )}

      {/* Payment Modal */}
      {showPaymentModal && (
        <PaymentModal
          isTenant={isTenant}
          user={userData}
          show={showPaymentModal}
          setShow={setShowPaymentModal}
        />
      )}

      {/* Reset Password */}
      {passwordEmailModal && (
        <ResetPasswordEmailModal
          userData={userData}
          show={passwordEmailModal}
          setShow={setPasswordEmailModal}
          handleResetPasswordEmail={handleResetPasswordEmail}
        />
      )}

      {userPasswordModal && (
        <ResetUserPasswordModal
          userData={userData}
          show={userPasswordModal}
          setShow={setUserPasswordModal}
          handleResetUserPassword={handleResetUserPassword}
        />
      )}

      {/* Edit Info */}
      {editUserModal && (
        <EditUserModal
          isTenant={isTenant}
          userData={userData}
          show={editUserModal}
          setShow={setEditUserModal}
        />
      )}

      {/* Bonus Issue Mdoal */}
      <UserBonusIssueModal
        isTenant={isTenant}
        userData={userData}
        showModal={showModal}
        bonusList={bonusList}
        bonusAmount={bonusAmount}
        bonusDetail={bonusDetail}
        setShowModal={setShowModal}
        selectedBonus={selectedBonus}
        setBonusAmount={setBonusAmount}
        setSelectedBonus={setSelectedBonus}
        issueBonusHandler={issueBonusHandler}
      />

      {/* FreeSpin Modal */}
      {freespinModal && (
        <FreeSpinBonusModal
          isTenant={isTenant}
          show={freespinModal}
          setShow={setFreeSpinModal}
          userData={userData}
        />
      )}

      {/* Add Affiliate Modal */}
      <AddAffiliateModal
        userData={userData}
        showAddAffiliate={showAddAffiliate}
        setShowAddAffiliate={setShowAddAffiliate}
        addAffiliateHandler={addAffiliateHandler}
      />

      {/* Remove Affiliate Modal */}
      <RemoveAffiliateModal
        userData={userData}
        showRemoveAffiliate={showRemoveAffiliate}
        setShowRemoveAffiliate={setShowRemoveAffiliate}
        removeAffiliateHandler={removeAffiliateHandler}
      />

      {/* Phone Verify Modal */}
      <VerifyPhoneModal
        userData={userData}
        verifyPhoneModal={verifyPhoneModal}
        setVerifyPhoneModal={setVerifyPhoneModal}
        handleYes={handleVerifyPhoneModal}
      />
    </>
  )
}

export default UserDetails
