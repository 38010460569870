import { takeLatest, put } from 'redux-saga/effects'
import {
  createCredentials,
  getAllCredKeys,
  getAllCreds,
  getCredByKey,
  updateCreds,
  getAllThemesTenant,
  updateThemeTenant,
  getBanners,
  uploadBanner,
  updateAffiliateSystem,
  getWhitelistDomains,
  updateWhitelistDomains,
  postWhitelistDomains,
  updateCrmIntegration,
  updateChatIntegration
} from '../../utils/apiCalls'

import {
  getAllCredKeysStart,
  getAllCredKeysSuccess,
  getAllCredKeysFailure,
  getAllCredStart,
  getAllCredSuccess,
  getAllCredFailure,
  createCredsStart,
  createCredsSuccess,
  createCredsFailure,
  getCredByKeyStart,
  getCredByKeySuccess,
  getCredByKeyFailure,
  updateCredsStart,
  updateCredsSuccess,
  updateCredsFailure,
  getAllThemesStart,
  getAllThemesSuccess,
  getAllThemesFailure,
  updateTenantThemeStart,
  updateTenantThemeSuccess,
  updateTenantThemeFailure,
  getAllBannersStart,
  getAllBannersSuccess,
  getAllBannersFailure,
  uploadBannerStart,
  uploadBannerComplete,
  updateAffiliateSystemStart,
  updateAffiliateSystemComplete,
  getWhiteListedDomainsStart,
  getWhiteListedDomainsFailure,
  getWhiteListedDomainsSuccess,
  updateWhiteListDomainsStart,
  updateWhiteListDomainsSuccess,
  updateWhiteListDomainsFailure,
  updateCrmIntegrationStart,
  updateCrmIntegrationComplete,
  updateChatIntegrationStart,
  updateChatIntegrationComplete
} from '../redux-slices/tenantcredentials'
import { objectToFormData } from '../../utils/objectToFormdata'
import { toast } from '../../components/Toast'
import { TenantRoutes } from '../../routes'
import { fetchTenantDetailsStart } from '../redux-slices/login'
import { serialize } from 'object-to-formdata'

export default function * credWatcher () {
  yield takeLatest(getAllCredKeysStart.type, getAllCredKeysWatcher)
  yield takeLatest(getAllCredStart.type, getAllCredWatcher)
  yield takeLatest(createCredsStart.type, createCredsWatcher)
  yield takeLatest(getCredByKeyStart.type, getCredByKeyWatcher)
  yield takeLatest(updateCredsStart.type, updateCredsWatcher)
  yield takeLatest(getAllThemesStart.type, getAllThemesWorker)
  yield takeLatest(updateTenantThemeStart.type, updateTenantThemeWorker)
  yield takeLatest(getAllBannersStart.type, getAllBannersWorker)
  yield takeLatest(uploadBannerStart.type, uploadBannerWorker)
  yield takeLatest(updateAffiliateSystemStart.type, updateAffiliateSystemWorker)
  yield takeLatest(getWhiteListedDomainsStart.type, getWhiteListedDomainsWorker)
  yield takeLatest(updateWhiteListDomainsStart.type, updateWhiteListDomainsWorker)
  yield takeLatest(updateCrmIntegrationStart.type, updateCrmIntegrationWorker)
  yield takeLatest(updateChatIntegrationStart.type, updateChatIntegrationWorker)
}

function * getAllCredKeysWatcher () {
  try {
    const { data } = yield getAllCredKeys()

    yield put(getAllCredKeysSuccess(data?.data?.credentialsKeys))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCredKeysFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllCredWatcher () {
  try {
    const { data } = yield getAllCreds()

    yield put(getAllCredSuccess(data?.data?.tenantCredentials))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllCredFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createCredsWatcher (action) {
  try {
    const { key, value, description, navigate } = action && action.payload

    yield createCredentials({ key, value, description })

    yield put(createCredsSuccess())
    yield toast('Credentials Created Successfully', 'success')
    navigate(TenantRoutes.Credentials, { state: 'credentials' })
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createCredsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCredByKeyWatcher (action) {
  try {
    const { credKey } = action && action.payload

    const { data } = yield getCredByKey({ credKey })

    yield put(getCredByKeySuccess(data?.data?.tenantCredential))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getCredByKeyFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateCredsWatcher (action) {
  try {
    const { key, value, description, navigate } = action && action.payload

    yield updateCreds({ key, value, description })

    yield put(updateCredsSuccess())
    yield toast('Credentials Updated Successfully', 'success')
    navigate(TenantRoutes.Credentials)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateCredsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateCrmIntegrationWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield updateCrmIntegration(data)
    yield toast('Updated Successfully', 'success')
    if (isTenant) {
      yield put(fetchTenantDetailsStart())
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  } finally {
    yield put(updateCrmIntegrationComplete())
  }
}

function * updateChatIntegrationWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield updateChatIntegration(data)
    yield toast('Updated Successfully', 'success')
    if (isTenant) {
      yield put(fetchTenantDetailsStart())
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  } finally {
    yield put(updateChatIntegrationComplete())
  }
}

function * getAllThemesWorker () {
  try {
    const { data } = yield getAllThemesTenant()

    yield put(getAllThemesSuccess(data?.data?.themes))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllThemesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateTenantThemeWorker (action) {
  try {
    const { logo, themeId, themeMode, primaryColor, secondaryColor } =
      action && action?.payload

    yield updateThemeTenant(
      objectToFormData({
        logo,
        themeId,
        themeMode,
        primaryColor,
        secondaryColor
      })
    )
    yield put(updateTenantThemeSuccess())

    yield toast('Tenant Theme updated succesfully', 'success')
    yield put(fetchTenantDetailsStart())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantThemeFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllBannersWorker (action) {
  try {
    const { tenantId } = action && action?.payload
    const { data } = yield getBanners({ tenantId })

    yield put(getAllBannersSuccess(data?.data?.banners))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllBannersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * uploadBannerWorker (action) {
  try {
    const { data: myData } = action && action?.payload
    const data = serialize(myData)

    yield uploadBanner(data)

    yield put(uploadBannerComplete())
    yield toast('Banner Updated SuccessFully', 'success')
    yield put(getAllBannersStart({ tenantId: myData?.tenantId }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(uploadBannerComplete())
  }
}

function * updateAffiliateSystemWorker (action) {
  try {
    const { data } = action && action.payload

    yield updateAffiliateSystem(data?.data)

    yield put(updateAffiliateSystemComplete())
    yield toast('Affiliate System Updated Successfully', 'success')
    yield put(fetchTenantDetailsStart())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateAffiliateSystemComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * getWhiteListedDomainsWorker () {
  try {
    const response = yield getWhitelistDomains()

    yield put(getWhiteListedDomainsSuccess(response?.data?.data?.data || []))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getWhiteListedDomainsFailure(e?.response?.data?.errors[0]?.description))
  }
}

/**
 * @param {{ data: { payload: { data: { domains: String[] }, isUpdate: Boolean}}}} action
 */
function * updateWhiteListDomainsWorker (action) {
  try {
    const { data, isUpdate = false } = action && action.payload

    if (isUpdate) {
      yield updateWhitelistDomains(data)
    } else {
      yield postWhitelistDomains(data)
    }

    yield toast('Whitelisted Domains Updated Successfully', 'success')

    // Fetch the updated list of whitelisted domains
    yield put(getWhiteListedDomainsStart())

    yield put(updateWhiteListDomainsSuccess())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description || 'Unexpected Error Occurred', 'error')
    yield put(updateWhiteListDomainsFailure(e?.response?.data?.errors[0]?.description))
  }
}
